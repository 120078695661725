<template>
  <div>
    <div>
      <label
        for="text"
        v-if="!data.properties.hideLabel"
        :style="getLabelStyles"
        >{{ data.label }}</label
      >
      <span
        v-show="data.validations.required && hasLabel && !isView"
        style="color: red; font-weight: bold"
      >
        *
      </span>
      <span>
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </span>
    </div>
    <div
      v-show="data.properties.filed_content != 'Hide' && !visibility"
      :style="getIsMobile ? '' : getStyle"
      class="rectangular-block"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponentsExecute-DIVExecute",
  props: ["data", "visibility"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getStyle() {
      return (
        `height:${this.data.height - 23}px;width:${
          this.data.width
        }px;overflow:auto;` + this.getElementStyle
      );
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0.4";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    },
  },
};
</script>

<style lang="scss">
.resposive-font {
  font-size: 1.2vw;
  line-height: 1.5;
}
.rectangular-block {
  background-color: transparent;
}
</style>
